// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgEmojiSad = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.5 11.75a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0M11.75 1C5.813 1 1 5.813 1 11.75S5.813 22.5 11.75 22.5 22.5 17.687 22.5 11.75 17.687 1 11.75 1M9.5 8.25a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0m-2.688 7.61c-.336-.242-.392-.696-.171-1.047.025-.039.041-.058.067-.088q.014-.015.032-.038.086-.106.245-.272c.213-.218.527-.503.947-.786.845-.569 2.114-1.128 3.818-1.128s2.973.559 3.818 1.128c.42.283.734.568.947.786a3.5 3.5 0 0 1 .344.398.75.75 0 0 1-.171 1.047c-.384.276-.81.113-1.083-.216a3 3 0 0 0-.165-.182 4.5 4.5 0 0 0-.71-.589c-.64-.43-1.622-.872-2.98-.872s-2.34.441-2.98.872a4.5 4.5 0 0 0-.71.589q-.114.119-.165.181c-.272.33-.7.493-1.083.217M16.5 8.25a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0"
    />
  </svg>
);
export const EmojiSadIcon = forwardRef(SvgEmojiSad);
